import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

/**
 * Constantes spécifiques alvéole
 */
export const vousetessur = 'Mes contacts';
export const titleAlveole = 'Mes contacts';

export const zUrlBackend = "https://api-mescontacts-dev.cristal-hub.fr";
export const zUrlBackNotifications = "https://api-servicenotification-dev.cristal-hub.fr";

// Local DeV
// export const zUrlBackend = "https://localhost:8000"
// export const zUrlBackNotifications = "https://localhost:8001";

/************************************************** Constantes ***********************************************/
export const zURLHome = "https://home-dev.cristal-hub.fr/customer";
export const zURLprofile = "https://profile-dev.cristal-hub.fr";

export const CristalContainerHeaderTitle = styled(Paper)(({theme}) => ({
    backgroundColor: 'unset',
    backgroundImage: 'unset',
    boxShadow: 'unset',
    padding: theme.spacing(1),
}));

export const CristalTextMessage = styled(TextField)(({theme}) => ({
    '& label': {
        fontSize: '16px',
        color: theme.palette.mode === 'light' ? '#00508F' : '#ffffff ',
        fontFamily: 'PoppinsRegular',
    },
    '& input': {
        paddingRight: '48px', // Ajustement pour le label
    },
    '& textarea': {
        fontFamily: 'PoppinsRegular',
    },
    '& fieldset': {
        fontSize: '16px',
        borderColor: theme.palette.mode === 'light' ? '#00508F' : '#ffffff',
        borderRadius: '10px',
    },
    '& .MuiInputBase-adornedEnd': {
        paddingRight: '16px'
    },
    marginBottom: '14px'
}));

export const CristalLinkBottomButtonMesContacts = styled(Button)(({theme}) => ({ //Bouton rechercher par personne
    color: theme.palette.getContrastText('#E11279'),
    backgroundColor: '#E11279',
    marginBottom: '0px',
    marginLeft: '0px',
    padding: '12px 20px',
    lineHeight: '1.5',
    textTransform: 'none',
    borderRadius: '50px',
    '&:hover': {
        backgroundColor: '#B40E61',
    },
}));

export const CristalFilterButton = styled(Button)(({theme}) => ({
    textTransform: 'none',
    ...theme.typography.body2,
    backgroundColor: theme.palette.mode === 'light' ? 'var(--background-global-03-light)' : 'var(--background-global-03-dark)',
    border: theme.palette.mode === 'light' ? '2px solid var(--border-card-light)' : '2px solid var(--border-card-dark)',
    '&:hover': {
        backgroundColor: theme.palette.mode === 'light' ? '#E5F9FE' : '#003B5D',
    },
    borderRadius: '10px',
    width: '100%',
    height: '65px',
    padding: '0 20px',
    marginBottom: '20px'
}));

export const isEmptyArray = (arr) => {
    return Array.isArray(arr) && arr.every(item => item.trim() === '');
};
